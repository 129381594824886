import { setRegisteredData } from "./forms/registered-data";

const checkLegimi = () => {
	$('#rule4, #rule5, #rule6, #rule7').prop('required', $('#legimi').prop('checked'));
	$('#rule4, #rule5, #rule6, #rule7').prop('disabled', !$('#legimi').prop('checked'));
	$('#rule4, #rule5, #rule6, #rule7').closest('.form-row').toggleClass('d-none', !$('#legimi').prop('checked'));
}
checkLegimi();
$('[name="personal_data[legimi]"]').on('change', () => {
	checkLegimi();
});

$('[name="personal_data[billType]"]').on('change', function() {
    $('[name="personal_data[billPos]"]', this.form.elements).closest('.form-group').prop('disabled', $(this).val() != 'Paragon');
}).trigger('change');

$('[name="personal_data[emailRepeat]"]').each(function() {
    let $email = $('[name="personal_data[email]"]', this.form.elements);
    let $emailRepeat = $('[name="personal_data[emailRepeat]"]', this.form.elements);
    $($email).add($emailRepeat).on('input', function() {
        $emailRepeat.toggleValid($email.val() == $emailRepeat.val());
    }).trigger('change');
});

$('#form-application').on('submit-success', function(event, data) {
	
	setRegisteredData(data, 'section#formularz');
	
	$('.page-form-application').addClass('d-none');
	$('.page-form-application-success').removeClass('d-none');
	
    $('html, body').animate({
        scrollTop: $(".page-form-application-success").offset().top
    }, 600);

    if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
            'event': 'new_lead',
            'leadID': '' + data.id + ''
        });
    }
    // $(`.result-${data.prize ? 'success' : 'failed'}`).removeClass('d-none');
    // if (data.prize) {
    //     $('.result-prize-name').html(data.prize.name);
    //     $('.result-form-link').attr('href', data.formUrl);
    // }
});

$('[name="personal_data[noPesel]"]').on('change', function() {
    $('[name="personal_data[pesel]"]').prop('disabled', $(this).prop('checked'));
    let fields = [
        '[name="personal_data[extraData][street]"]',
        '[name="personal_data[extraData][streetNr]"]',
        '[name="personal_data[extraData][flatNr]"]',
        '[name="personal_data[extraData][zipCode]"]',
        '[name="personal_data[extraData][city]"]',
        '[name="personal_data[extraData][citizenship]"]',
    ];
    $(fields.join(', ')).prop('disabled', !$(this).prop('checked'))
    if (!$(this).prop('checked')) {
        $(fields.join(', ')).closest('.form-group').addClass('disabled');
        $('#no-pesel-header').addClass('d-none');
    } else {
        $(fields.join(', ')).closest('.form-group').removeClass('disabled');
        $('#no-pesel-header').removeClass('d-none');
    }
}).trigger('change');

$('#winner-form').on('submit-success', function(event, data) {
	$('.page').addClass('d-none');
	$('.page-thank-you').removeClass('d-none');
});

$('#form-contact').on('submit-success', function(event, data) {
	$('.page-form-contact').addClass('d-none');
	$('.page-form-contact-success').removeClass('d-none');
});