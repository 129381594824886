// Define datepicker options
const datepicker = {
	options: {
		default: {
			language: "pl",
			format: "dd.mm.yyyy",
			clearBtn: true,
			maxViewMode: 3,
			startDate: new Date('01/01/1900'),
			endDate: new Date(),
			defaultViewDate: new Date(),
		},
		bill: {
			language: "pl",
			format: "dd.mm.yyyy",
			clearBtn: true,
			maxViewMode: 1,
			defaultViewDate: new Date(),
		},
		dob: {
			language: "pl",
			format: "dd.mm.yyyy",
			clearBtn: true,
			maxViewMode: 2,
			startDate: new Date('01/01/1900'),
			endDate: new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
			defaultViewDate: new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
		}
	}
};

export default datepicker;