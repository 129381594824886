import Swiper from 'swiper'
import { Navigation, EffectFade } from 'swiper/modules'
import 'swiper/css'

const navigationButtonDisplay = (swiper, prev = null, next = null) => {
	if (!swiper) return
	swiper.isBeginning ? prev?.classList.add('hide') : prev?.classList.remove('hide')
	swiper.isEnd ? next?.classList.add('hide') : next?.classList.remove('hide')
}

const element = document.querySelector('#swiper-winners .swiper')
const navigation = {
	prev: document.querySelector('#swiper-winners .swiper-button-prev'),
	next: document.querySelector('#swiper-winners .swiper-button-next'),
}

const swiperWinners = new Swiper(element, {
	modules: [ Navigation, EffectFade ],
	navigation: {
		prevEl: navigation.prev,
		nextEl: navigation.next,
	},
	effect: 'fade',
	fadeEffect: {
		crossFade: true
	},
	allowTouchMove: false
})

swiperWinners.on('slideChange', () => {
	navigationButtonDisplay(swiperWinners, navigation.prev, navigation.next)
})
swiperWinners.on('breakpoint', () => {
	navigationButtonDisplay(swiperWinners, navigation.prev, navigation.next)
})
navigationButtonDisplay(swiperWinners, navigation.prev, navigation.next)