import SimpleBar from 'simplebar'
import 'simplebar/dist/simplebar.css'

const lists = document.querySelectorAll('.list-winners-daily')

lists.forEach(list => {
	new SimpleBar(list, {
		autoHide: false,
		scrollbarMaxSize: 52,
	})
})